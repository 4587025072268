import React from 'react';
import { Scene, ApiVersion } from '../../../types';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import FinalScene from '../FinalScene/FinalScene';
import './SceneView.scss';

interface SceneViewProps {
  scene: Scene;
  score: number;
  isLoading: boolean;
  fadeOut: boolean;
  apiVersion: ApiVersion;
  onChoiceClick: (choiceId: string) => void;
  onRestart: () => void;
  onBackToList: () => void;
}

const SceneView: React.FC<SceneViewProps> = ({
  scene,
  score,
  isLoading,
  fadeOut,
  apiVersion,
  onChoiceClick,
  onRestart,
  onBackToList
}) => {
  if (scene.is_final) {
    return (
      <FinalScene
        scene={scene}
        score={score}
        onRestart={onRestart}
        onBackToList={onBackToList}
      />
    );
  }

  return (
    <div className="scene-container">
      {isLoading && <LoadingSpinner fullScreen size="large" />}
      
      {scene.images && scene.images.length > 0 && (
        <div 
          className={`scene-background ${fadeOut ? 'fade-out' : ''}`}
          style={{ backgroundImage: `url(${scene.images[0]})` }}
        />
      )}
      
      <div className={`scene-content ${fadeOut ? 'fade-out' : ''}`}>
        <div className="scene-text">
          {scene.content.split('\n').map((paragraph: string, index: number) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
        
        {!scene.is_final && (
          <div className="choices-container">
            <div className="choices-grid">
              {Object.entries(scene.choices_scenes_mapping).map(([choiceId, nextSceneId], index) => (
                <button
                  key={choiceId}
                  onClick={() => onChoiceClick(choiceId)}
                  disabled={isLoading}
                  className={`choice-button choice-variant-${index % 3}`}
                >
                  {isLoading ? (
                    <LoadingSpinner size="small" />
                  ) : (
                    apiVersion === 'v4' && scene.choices ? 
                      scene.choices[choiceId] : 
                      choiceId
                  )}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SceneView;